var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c(
          "v-row",
          [
            _vm.campaignIdData
              ? _c(
                  "v-col",
                  { staticClass: "pb-2", attrs: { cols: "12" } },
                  [
                    _c("CampaignHeader", {
                      ref: "campaignHeader",
                      attrs: { disabled: "", "campaign-id": _vm.campaignIdData }
                    })
                  ],
                  1
                )
              : _c(
                  "v-col",
                  { staticClass: "pb-2", attrs: { cols: "12" } },
                  [
                    _c(
                      "v-expansion-panels",
                      {
                        staticStyle: { height: "72px" },
                        attrs: { disabled: "" }
                      },
                      [
                        _c(
                          "v-expansion-panel",
                          [
                            _c(
                              "v-expansion-panel-header",
                              { staticClass: "text-h6" },
                              [
                                _vm._v(
                                  "\n              Campaign Details\n            "
                                )
                              ]
                            ),
                            _c("v-expansion-panel-content")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
            _c(
              "v-col",
              { staticClass: "pb-1", attrs: { cols: "4", xl: "3" } },
              [
                _c("v-select", {
                  attrs: {
                    dense: "",
                    "hide-details": "",
                    items: _vm.metricOptions,
                    outlined: "",
                    label: "Event Type",
                    color: "primary"
                  },
                  model: {
                    value: _vm.metric,
                    callback: function($$v) {
                      _vm.metric = $$v
                    },
                    expression: "metric"
                  }
                })
              ],
              1
            ),
            _vm.showViewAsCustomer
              ? _c(
                  "v-col",
                  { staticClass: "pb-1", attrs: { cols: "4", xl: "3" } },
                  [_c("ViewAsCustomer")],
                  1
                )
              : _vm._e(),
            _c(
              "v-col",
              { staticClass: "pb-1", attrs: { cols: "4", xl: "3" } },
              [
                _c("v-autocomplete", {
                  attrs: {
                    dense: "",
                    "hide-details": "",
                    items: _vm.computedCampaigns,
                    "item-text": "campaignName",
                    "item-value": "campaignId",
                    "search-input": _vm.campaignSearch,
                    loading: _vm.campaignsLoading,
                    clearable: "",
                    outlined: "",
                    label: "Campaign",
                    color: "primary"
                  },
                  on: {
                    "update:searchInput": function($event) {
                      _vm.campaignSearch = $event
                    },
                    "update:search-input": function($event) {
                      _vm.campaignSearch = $event
                    },
                    change: _vm.changeCampaign,
                    "click:clear": _vm.clearCampaign
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "append-outer",
                      fn: function() {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              disabled: !_vm.campaignIdData,
                                              color: "success"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.$_dashMixin_nowwEditLink(
                                                  {
                                                    fileType:
                                                      _vm.selectedCampaign
                                                        .campaignFileType,
                                                    jobNumber:
                                                      _vm.selectedCampaign
                                                        .campaignNowwOrderNumber,
                                                    fileTypeId:
                                                      _vm.selectedCampaign
                                                        .campaignFileTypeId
                                                  }
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                  mdi-bullseye-arrow\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [_c("span", [_vm._v("View Target Audience")])]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.campaignIdData,
                    callback: function($$v) {
                      _vm.campaignIdData = $$v
                    },
                    expression: "campaignIdData"
                  }
                })
              ],
              1
            ),
            _c(
              "v-col",
              { staticClass: "pb-1", attrs: { cols: "12", xl: "3" } },
              [
                _c("v-select", {
                  attrs: {
                    "item-text": "text2",
                    "item-value": "acousticMailingId",
                    dense: "",
                    "hide-details": "",
                    multiple: "",
                    items: _vm.mailingsList,
                    outlined: "",
                    label: "Mailings",
                    color: "primary",
                    loading: _vm.mailingsLoading
                  },
                  model: {
                    value: _vm.mailingsSelected,
                    callback: function($$v) {
                      _vm.mailingsSelected = $$v
                    },
                    expression: "mailingsSelected"
                  }
                })
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12" } },
              [
                _vm.mapData || _vm.stateSummaries.length > 1
                  ? _c(
                      "v-expansion-panels",
                      {
                        attrs: { multiple: "" },
                        model: {
                          value: _vm.panel,
                          callback: function($$v) {
                            _vm.panel = $$v
                          },
                          expression: "panel"
                        }
                      },
                      [
                        _vm.mapData
                          ? _c(
                              "v-expansion-panel",
                              { key: 0 },
                              [
                                _c(
                                  "v-expansion-panel-header",
                                  { staticClass: "text-h6" },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "8",
                                              "no-gutters": ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Campaign Events Heatmap\n                "
                                            )
                                          ]
                                        ),
                                        _c("v-spacer"),
                                        _vm.selectedCampaign
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "text-right",
                                                attrs: {
                                                  cols: "1",
                                                  "no-gutters": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var onTooltip =
                                                              ref.on
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mr-4",
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm
                                                                          .mailingsSelected
                                                                          .length ===
                                                                        0,
                                                                      color:
                                                                        "success"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.$_downloadFileMixin_downloadGeoData(
                                                                          _vm.getGeoDataDownloadFormat(),
                                                                          _vm.selectedCampaign.campaignName.replace(
                                                                            / /g,
                                                                            ""
                                                                          ) +
                                                                            "-" +
                                                                            _vm.selectedMailings
                                                                              .map(
                                                                                function(
                                                                                  x
                                                                                ) {
                                                                                  return x.mailingSequence ===
                                                                                    1
                                                                                    ? "B"
                                                                                    : "R" +
                                                                                        (x.mailingSequence -
                                                                                          1)
                                                                                }
                                                                              )
                                                                              .join(
                                                                                "_"
                                                                              ) +
                                                                            "-HeatmapData"
                                                                        )
                                                                      }
                                                                    },
                                                                    nativeOn: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                      }
                                                                    }
                                                                  },
                                                                  onTooltip
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "\n                        mdi-file-download\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1297612406
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Download Heatmap Data"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(
                                      "DxVectorMap",
                                      {
                                        ref: _vm.vectorMapRef,
                                        style: {
                                          minHeight:
                                            _vm.windowMixin_containerMinHeight -
                                            208 +
                                            "px"
                                        },
                                        attrs: {
                                          id: "vector-map",
                                          bounds: _vm.bounds,
                                          "zoom-factor": 3,
                                          "wheel-enabled": false,
                                          center: [-96, 38]
                                        },
                                        on: {
                                          click: _vm.click,
                                          initialized: _vm.onInitialized
                                        }
                                      },
                                      [
                                        _c("DxLayer", {
                                          attrs: {
                                            "data-source": _vm.usStates,
                                            name: "areasStates",
                                            color: "#ffffff00",
                                            "border-color": "#000000",
                                            "border-width": 2
                                          }
                                        }),
                                        _c("DxLayer", {
                                          attrs: {
                                            "data-source": _vm.usCounties,
                                            "color-groups":
                                              _vm.colorGroupAssigned,
                                            customize: _vm.customizeLayer,
                                            name: "areasCounties",
                                            "color-grouping-field": _vm.metric,
                                            palette: "Violet",
                                            type: "area",
                                            opacity: 0.6
                                          }
                                        }),
                                        _c(
                                          "DxTooltip",
                                          {
                                            attrs: {
                                              enabled: true,
                                              "customize-tooltip":
                                                _vm.customizeTooltip,
                                              "z-index": 5
                                            }
                                          },
                                          [
                                            _c("DxBorder", {
                                              attrs: { visible: true }
                                            }),
                                            _c("DxFont", {
                                              attrs: { color: "#000000" }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "DxTitle",
                                          {
                                            attrs: {
                                              text:
                                                _vm.selectedMetric.text +
                                                " event across the US"
                                            }
                                          },
                                          [
                                            _c("DxSubtitle", {
                                              attrs: {
                                                text:
                                                  _vm.selectedMetric
                                                    .description +
                                                  " (for the selected mailings)"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "DxLegend",
                                          {
                                            attrs: {
                                              "customize-items":
                                                _vm.customizeLegendItems,
                                              "customize-text":
                                                _vm.customizeLegendText,
                                              "horizontal-alignment": "right",
                                              "vertical-alignment": "bottom"
                                            }
                                          },
                                          [
                                            _c("DxSource", {
                                              attrs: {
                                                layer: "areasCounties",
                                                grouping: "color"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "DxLegend",
                                          {
                                            attrs: {
                                              title: {
                                                text: _vm.getLegendTitle(),
                                                font: {
                                                  family:
                                                    "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
                                                },
                                                margin: { bottom: 5, top: 10 }
                                              },
                                              "horizontal-alignment": "right",
                                              "vertical-alignment": "top"
                                            }
                                          },
                                          [
                                            _c("DxSource", {
                                              attrs: { layer: "areasCounties" }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-expansion-panel",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.stateSummaries.length > 1,
                                expression: "stateSummaries.length > 1"
                              }
                            ],
                            key: 1
                          },
                          [
                            _c(
                              "v-expansion-panel-header",
                              { staticClass: "text-h6" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "8", "no-gutters": "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Top Performing States\n                "
                                        )
                                      ]
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { cols: "1", "no-gutters": "" }
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-4",
                                                            attrs: {
                                                              color: "success"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.$_downloadFileMixin_downloadReportChart(
                                                                  _vm.stateSummariesChartData,
                                                                  _vm
                                                                    .selectedMetric
                                                                    .append,
                                                                  _vm
                                                                    .selectedCampaign
                                                                    .campaignNowwOrderNumber +
                                                                    "-" +
                                                                    _vm.selectedCampaign.campaignName.replace(
                                                                      / /g,
                                                                      ""
                                                                    ) +
                                                                    "-TopPerformingStates",
                                                                  true
                                                                )
                                                              }
                                                            },
                                                            nativeOn: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                              }
                                                            }
                                                          },
                                                          onTooltip
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                        mdi-file-download\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2361891691
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Download Top Performing States"
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.displayChartPanel
                              ? _c(
                                  "v-expansion-panel-content",
                                  { staticStyle: { height: "400px" } },
                                  [
                                    _c("bar-chart", {
                                      attrs: {
                                        "chart-data":
                                          _vm.stateSummariesChartData,
                                        options: _vm.getChartOptionsBar(
                                          _vm.selectedMetric.description,
                                          _vm.selectedMetric
                                        ),
                                        height: 400
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm.mapDataLoading
                  ? _c("v-skeleton-loader", { attrs: { type: "image@4" } })
                  : _c("div", [
                      _vm.showFileTypeError
                        ? _c("p", { staticClass: "red--text" }, [
                            _vm._v(
                              "\n            The selected Campaign either has a mailing that is still deploying, or a Target Audience File Type that is not supported for heatmap visualization.\n          "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(
                        "\n          Select a campaign to get event data.\n        "
                      )
                    ])
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }