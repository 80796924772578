<template>
  <div>
    <template>
      <v-row>
        <v-col
          v-if="campaignIdData"
          cols="12"
          class="pb-2"
        >
          <CampaignHeader
            ref="campaignHeader"
            disabled
            :campaign-id="campaignIdData"
          />
        </v-col>
        <v-col
          v-else
          cols="12"
          class="pb-2"
        >
          <v-expansion-panels
            disabled
            style="height: 72px;"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="text-h6">
                Campaign Details
              </v-expansion-panel-header>
              <v-expansion-panel-content />
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col
          cols="4"
          xl="3"
          class="pb-1"
        >
          <v-select
            v-model="metric"
            dense
            hide-details
            :items="metricOptions"
            outlined
            :label="`Event Type`"
            color="primary"
          />
        </v-col>
        <v-col
          v-if="showViewAsCustomer"
          cols="4"
          xl="3"
          class="pb-1"
        >
          <ViewAsCustomer />
        </v-col>
        <v-col
          cols="4"
          xl="3"
          class="pb-1"
        >
          <v-autocomplete
            v-model="campaignIdData"
            dense
            hide-details
            :items="computedCampaigns"
            item-text="campaignName"
            item-value="campaignId"
            :search-input.sync="campaignSearch"
            :loading="campaignsLoading"
            clearable
            outlined
            label="Campaign"
            color="primary"
            @change="changeCampaign"
            @click:clear="clearCampaign"
          >
            <template v-slot:append-outer>
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    :disabled="!campaignIdData"
                    class="mr-2"
                    color="success"
                    @click="$_dashMixin_nowwEditLink({ fileType: selectedCampaign.campaignFileType, jobNumber: selectedCampaign.campaignNowwOrderNumber, fileTypeId: selectedCampaign.campaignFileTypeId })"
                    v-on="on"
                  >
                    mdi-bullseye-arrow
                  </v-icon>
                </template>
                <span>View Target Audience</span>
              </v-tooltip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          xl="3"
          class="pb-1"
        >
          <v-select
            v-model="mailingsSelected"
            item-text="text2"
            item-value="acousticMailingId"
            dense
            hide-details
            multiple
            :items="mailingsList"
            outlined
            :label="`Mailings`"
            color="primary"
            :loading="mailingsLoading"
          >
            <!-- <template v-slot:selection="{ item, index }">
              <span v-if="mailingsList.">

              </span> -->
            <!-- <v-chip v-if="index === 0">
                <span>{{ item }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ mailingsSelected.length - 1 }} others)
              </span> -->
            <!-- </template> -->
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-expansion-panels
            v-if="mapData || stateSummaries.length > 1"
            v-model="panel"
            multiple
          >
            <v-expansion-panel
              v-if="mapData"
              :key="0"
            >
              <v-expansion-panel-header class="text-h6">
                <v-row no-gutters>
                  <v-col
                    cols="8"
                    no-gutters
                  >
                    Campaign Events Heatmap
                  </v-col>
                  <v-spacer />
                  <v-col
                    v-if="selectedCampaign"
                    cols="1"
                    no-gutters
                    class="text-right"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on: onTooltip }">
                        <v-icon
                          :disabled="mailingsSelected.length === 0"
                          color="success"
                          class="mr-4"
                          v-on="onTooltip"
                          @click.native.stop
                          @click="$_downloadFileMixin_downloadGeoData(getGeoDataDownloadFormat(), `${selectedCampaign.campaignName.replace(/ /g, '')}-${selectedMailings.map(x => x.mailingSequence === 1 ? 'B' : `R${x.mailingSequence - 1}`).join('_')}-HeatmapData`)"
                        >
                          mdi-file-download
                        </v-icon>
                      </template>
                      <span>Download Heatmap Data</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <DxVectorMap
                  id="vector-map"
                  :ref="vectorMapRef"
                  :bounds="bounds"
                  :zoom-factor="3"
                  :wheel-enabled="false"
                  :center="[-96,38]"
                  :style="{ minHeight: windowMixin_containerMinHeight - 208 + 'px'}"
                  @click="click"
                  @initialized="onInitialized"
                >
                  <DxLayer
                    :data-source="usStates"
                    name="areasStates"
                    color="#ffffff00"
                    border-color="#000000"
                    :border-width="2"
                  />
                  <DxLayer
                    :data-source="usCounties"
                    :color-groups="colorGroupAssigned"
                    :customize="customizeLayer"
                    name="areasCounties"
                    :color-grouping-field="metric"
                    palette="Violet"
                    type="area"
                    :opacity="0.60"
                  />
                  <DxTooltip
                    :enabled="true"
                    :customize-tooltip="customizeTooltip"
                    :z-index="5"
                  >
                    <DxBorder :visible="true" />
                    <DxFont color="#000000" />
                  </DxTooltip>

                  <DxTitle :text="`${selectedMetric.text} event across the US`">
                    <DxSubtitle :text="`${selectedMetric.description} (for the selected mailings)`" />
                  </DxTitle>

                  <DxLegend
                    :customize-items="customizeLegendItems"
                    :customize-text="customizeLegendText"
                    horizontal-alignment="right"
                    vertical-alignment="bottom"
                  >
                    <DxSource
                      layer="areasCounties"
                      grouping="color"
                    />
                  </DxLegend>

                  <DxLegend
                    :title="{ text: getLegendTitle(),
                              font: { family: '\'Century Gothic\',\'CenturyGothic\',\'AppleGothic\',\'Roboto\',\'sans-serif\'' },
                              margin: { bottom: 5, top: 10 } }"
                    horizontal-alignment="right"
                    vertical-alignment="top"
                  >
                    <DxSource
                      layer="areasCounties"
                    />
                  </DxLegend>
                </DxVectorMap>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              v-show="stateSummaries.length > 1"
              :key="1"
            >
              <v-expansion-panel-header class="text-h6">
                <v-row no-gutters>
                  <v-col
                    cols="8"
                    no-gutters
                  >
                    Top Performing States
                  </v-col>
                  <v-spacer />
                  <v-col
                    cols="1"
                    no-gutters
                    class="text-right"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on: onTooltip }">
                        <v-icon
                          color="success"
                          class="mr-4"
                          v-on="onTooltip"
                          @click.native.stop
                          @click="$_downloadFileMixin_downloadReportChart(stateSummariesChartData, selectedMetric.append, `${selectedCampaign.campaignNowwOrderNumber}-${selectedCampaign.campaignName.replace(/ /g, '')}-TopPerformingStates`, true)"
                        >
                          mdi-file-download
                        </v-icon>
                      </template>
                      <span>Download Top Performing States</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                v-if="displayChartPanel"
                style="height: 400px;"
              >
                <bar-chart
                  :chart-data="stateSummariesChartData"
                  :options="getChartOptionsBar(selectedMetric.description, selectedMetric)"
                  :height="400"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-skeleton-loader
            v-else-if="mapDataLoading"
            type="image@4"
          />
          <div v-else>
            <p
              v-if="showFileTypeError"
              class="red--text"
            >
              The selected Campaign either has a mailing that is still deploying, or a Target Audience File Type that is not supported for heatmap visualization.
            </p>
            Select a campaign to get event data.
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import mutationTypes from '@/store/mutation-types'
import { mailingService } from '@/shared/services'
import claims from '@/shared/models/auth/claims'
import { dashboardMixin } from '@/shared/mixins/mailing'
import { windowMixin, downloadFileMixin } from '@/shared/mixins/general'
// import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
import { debounce } from 'lodash'

import {
  DxVectorMap,
  DxLayer,
  DxLegend,
  DxSource,
  DxSubtitle,
  DxTitle,
  DxTooltip,
  DxBorder,
  DxFont
} from 'devextreme-vue/vector-map'

const { format } = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0
})

export default {
  name: 'CampaignEventsHeatmap',

  components: {
    DxVectorMap,
    DxLayer,
    DxLegend,
    DxSource,
    DxTooltip,
    DxSubtitle,
    DxTitle,
    DxBorder,
    DxFont,
    ViewAsCustomer: () => import('@/views/pages/components/user-state/ViewAsCustomer'),
    BarChart: () => import('@/views/pages/components/charts/BarChart'),
    CampaignHeader: () => import('@/views/pages/components/mailing/CampaignHeader')
  },

  mixins: [dashboardMixin, windowMixin, downloadFileMixin],

  props: {
    campaignId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      colorGroups: [1, 10, 25, 50, 100, 250, 500, 1000, 2500, 1000000000],
      colorGroupsPercent: [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 1000000000],
      colorGroupsReadSeconds: [1, 2, 3, 4, 6, 8, 10, 15, 30, 60, 1000000000],
      colorGroupAssigned: [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 1000000000],
      usCounties: null,
      usStates: null,
      vectorMapRef: 'vectorMapRef',
      vectorMapInstance: null,
      onInitialized: (e) => {
        this.vectorMapInstance = e.component
      },
      mapData: null,
      mapDataLoading: false,
      stateSummaries: [],
      bounds: [-130, 74, -68, 8], // [-118, 52, -80, 20],
      metric: 'uniqueOpenRate',
      metricOptions: [
        { text: 'Received', value: 'received', append: '', precision: 0, description: 'The number of emails that made it to the inboxes of your Target Audience' },
        { text: 'Opens', value: 'uniqueOpens', append: '', precision: 0, description: 'The number of individual recipients that opened your email' },
        { text: 'Clicks', value: 'uniqueClicks', append: '', precision: 0, description: 'The number of individual recipients that clicked on at least one link in your email' },
        { text: 'Click to Open Rate (%)', value: 'clickToOpen', append: '%', numerator: 'uniqueClicks', denominator: 'uniqueOpens', factor: 100, precision: 2, description: 'Out of the individual audience members that opened your email, the percentage that clicked on a link' },
        { text: 'Open Rate (%)', value: 'uniqueOpenRate', append: '%', numerator: 'uniqueOpens', denominator: 'received', factor: 100, precision: 2, description: 'Out of the audience members that received your email, the percentage that opened your email' },
        { text: 'Click Rate (%)', value: 'uniqueClickRate', append: '%', numerator: 'uniqueClicks', denominator: 'received', factor: 100, precision: 2, description: 'Out of the audience members that received your email, the percentage that clicked on a link' },
        { text: 'Average Viewing Time (s)', value: 'averageViewingSeconds', append: 's', numerator: 'totalViewingSeconds', denominator: 'grossOpens', factor: 1, precision: 2, description: 'The average time spent viewing your email for each open' },
        { text: 'Total Viewing Time (s)', value: 'totalViewingSeconds', append: 's', precision: 0, description: 'The total time spent viewing your email' }
        // { text: 'Sent', value: 'sent', description: 'The number of emails sent to your Target Audience' },
        // { text: 'Gross Opens', value: 'grossOpens', append: '', description: 'The number of emails that were opened (including repeats from individual audience members)' },
        // { text: 'Gross Clicks', value: 'grossClicks', append: '', description: 'The number of links that were clicks (including repeats from individual audience members)' },
        // { text: 'Glanced Count', value: 'glancedCount', append: '' },
        // { text: 'Skimmed Count', value: 'skimmedCount', append: '' },
        // { text: 'Read Count', value: 'readCount', append: '' },
        // { text: 'Glanced Rate', value: 'glancedRate', append: '%', numerator: 'glancedCount', denominator: 'totalViewCount', factor: 100 },
        // { text: 'Skimmed Rate', value: 'skimmedRate', append: '%', numerator: 'skimmedCount', denominator: 'totalViewCount', factor: 100 },
        // { text: 'Read Rate', value: 'readRate', append: '%', numerator: 'readCount', denominator: 'totalViewCount', factor: 100 },
        // { text: 'Opt Outs', value: 'optOuts' },
        // { text: 'Bounces', value: 'bounces' },
        // { text: 'Suppressed', value: 'suppressed' },
        // { text: 'Blocks', value: 'blocks' }
      ],
      campaignSearch: '',
      campaignIdData: this.campaignId,
      campaigns: [],
      campaignsLoading: false,
      mailingsList: [],
      mailingsSelected: [],
      mailingsLoading: false,
      unsubscribe: null,
      panel: [0, 1],
      showFileTypeError: false
    }
  },

  computed: {
    ...mapGetters('auth', ['hasClaimKV']),
    computedCampaigns () {
      return this.campaigns
    },
    computedMapData () {
      if (!this.mapData) return this.mapData
      return this.mapData.filter(x => this.mailingsSelected.includes(x.acousticMailingId))
    },
    showViewAsCustomer () {
      return this.hasClaimKV(claims.MMS_TEBT)
    },
    selectedCampaign () {
      if (!this.campaigns) return this.campaigns
      return this.campaigns.find(x => x.campaignId === this.campaignIdData)
    },
    selectedMailings () {
      if (!this.mailingsList) return this.mailingsList
      return this.mailingsList.filter(x => this.mailingsSelected.includes(x.acousticMailingId))
    },
    selectedMetric () {
      return this.metricOptions.find(x => x.value === this.metric)
    },
    // selectedMailingReceivedCountSum () {
    //   if (!this.mailingsList || !this.mailingsSelected) return 0
    //   return this.mailingsList.filter(x => this.mailingsSelected.includes(x.acousticMailingId)).map(x => x.received).reduce((prev, next) => prev + next, 0)
    // },
    computedColorGroup () {
      if (this.selectedMetric.append === '%') {
        return this.colorGroupsPercent
      }
      if (this.selectedMetric.value === 'averageViewingSeconds') {
        return this.colorGroupsReadSeconds
      }
      return this.colorGroups
    },
    stateSummariesChartData () {
      if (!this.stateSummaries) return {}
      var filteredStateSummary = this.stateSummaries.filter(x => this.mailingsSelected.includes(x.acousticMailingId))
      var result = []
      var metric = this.selectedMetric
      filteredStateSummary.reduce(function (res, value) {
        if (!res[value.stateId]) {
          res[value.stateId] = { stateId: value.stateId, stateName: value.stateName, numerator: 0, denominator: 0 }
          res[value.stateId][metric.value] = 0
          result.push(res[value.stateId])
        }
        if (metric.numerator && metric.denominator && metric.factor) {
          res[value.stateId].numerator += value[metric.numerator]
          res[value.stateId].denominator += value[metric.denominator]
        } else {
          res[value.stateId][metric.value] += value[metric.value]
        }
        return res
      }, {})
      if (metric.numerator && metric.denominator && metric.factor) {
        result.forEach((item, index) => {
          if (item.denominator === 0) {
            item[metric.value] = 0
          } else {
            item[metric.value] = item.numerator / item.denominator * metric.factor
          }
        })
      }
      result = result.sort((a, b) => b[metric.value] - a[metric.value])
      var chart = {
        labels: result.map(x => x.stateName)
      }
      chart.datasets = [
        {
          label: metric.text,
          data: result.map(x => x[metric.value].toFixed(2)),
          backgroundColor: '#7C4DFF',
          borderColor: '#673AB7'
        }
      ]
      return chart
    },
    displayChartPanel () {
      return this.panel.includes(1)
    }
  },

  watch: {
    '$route.params.campaignId': {
      immediate: true,
      deep: true,
      async handler (newValue, oldValue) {
        this.campaignIdData = newValue
        if (!oldValue) {
          await mailingService.getCampaign(this.campaignIdData).then(async resp => {
            if (resp.errorResponse) {
              this.$store.commit('setGlobalSnackbar', {
                message: resp.errorResponse,
                color: 'error'
              })
            } else if (this.hasClaimKV(claims.MMS_TEBT)) {
              await this.$store.dispatch('setViewAsCustomer', {
                crmId: resp.tenantCrmId,
                nowwId: resp.campaignTenantNowwId,
                isContracted: resp.campaignTenantIsContracted
              }).then(async () => {
                await this.getCampaigns()
              })
            } else {
              await this.getCampaigns()
            }
          })
        }
        if (newValue) {
          await this.getMailingsByCampaign()
          if (!this.selectedCampaign) {
            this.showFileTypeError = true
            this.mailingsList = []
            this.mailingsSelected = []
          } else {
            this.showFileTypeError = false
            await this.getEvents(this.selectedCampaign.campaignFileType, this.mailingsSelected)
          }
        }
      }
    },
    computedMapData: {
      deep: true,
      handler (newValue, oldValue) {
        this.updateVectorMap()
      }
    },
    campaignSearch () {
      this.debouncedSearch()
    },
    metric () {
      this.colorGroupAssigned = this.computedColorGroup
    }
    // mailingsSelected: {
    //   // immediate: true,
    //   deep: true,
    //   handler (newValue, oldValue) {
    //     if (!this.mapData) {
    //       this.getEvents(this.campaigns.find(x => x.campaignId === this.campaignIdData).campaignFileType, newValue)
    //     }
    //   }
    // }
  },

  created () {
    if (!this.showViewAsCustomer) {
      this.getCampaigns()

      if (!this.selectedCampaign && this.campaignIdData === null) {
        this.showFileTypeError = true
        this.mailingsList = []
        this.mailingsSelected = []
      } else {
        this.getMailingsByCampaign()
      }
    }
    this.getCountiesMap()
    this.getStatesMap()
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.clearCampaign()
        this.getCampaigns()
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  methods: {
    async getCampaigns () {
      this.campaignsLoading = true
      await mailingService.getCompletedCampaignsList(this.$store.getters['simulatedCustomerCrmId'](), this.campaignSearch, true).then(resp => {
        if (resp.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
        } else {
          this.campaigns = resp
        }
      }).finally(() => {
        this.campaignsLoading = false
      })
    },
    async getMailingsByCampaign () {
      this.mailingsLoading = true
      await mailingService.getMailingsByCampaign(this.campaignIdData).then(resp => {
        if (resp.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
        } else {
          this.mailingsList = resp
          this.mailingsSelected = this.mailingsList.map(x => x.acousticMailingId)
        }
      }).finally(() => {
        this.mailingsLoading = false
      })
    },
    async getEvents (fileType, acousticMailingIds) {
      this.mapDataLoading = true
      await mailingService.getGeoEventsByCounty(fileType, acousticMailingIds).then(resp => {
        if (resp.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
        } else {
          this.mapData = resp.items
          this.stateSummaries = resp.stateSummaries
        }
      }).finally(() => {
        this.mapDataLoading = false
      })
    },
    async getCountiesMap () {
      await mailingService.getUSCountiesJSON().then(resp => {
        if (resp.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
        } else {
          this.usCounties = resp
        }
      })
    },
    async getStatesMap () {
      await mailingService.getUSStatesJSON().then(resp => {
        if (resp.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
        } else {
          this.usStates = resp
        }
      })
    },
    debouncedSearch: debounce(function () {
      this.getCampaigns()
    }, 700),
    updateVectorMap () {
      if (!this.vectorMapInstance) return
      var elements = this.vectorMapInstance.getLayers()[1].getElements()
      this.customizeLayer(elements)
    },
    customizeTooltip (info) {
      const name = info.attribute('NAME')
      const county = info.attribute('LSAD')
      const stateName = this.usStates.features.find(x => x.properties.STATE === info.attribute('STATE')).properties.NAME
      if (name && county) {
        let txt = `<div style="text-align: left;">`
        txt += `<strong>${name} ${county}, ${stateName}</strong><br>`
        var filtered = this.computedMapData.filter(x => x.geO_ID === info.attribute('GEO_ID'))
        this.metricOptions.forEach((option) => {
          txt += `${option.value === this.metric ? '<strong>' : ''}${option.text}: `
          txt += this.getAggregateMetricValue(option, filtered)
          txt += `${option.append}${option.value === this.metric ? '</strong>' : ''}<br>`
        })
        txt += `</div>`
        return {
          html: txt,
          color: '#ffffff'
        }
      }
      return null
    },
    click ({ target }) {
      if (target) {
        target.selected(!target.selected())
      }
    },
    customizeLayer (elements) {
      if (!this.computedMapData) {
        return
      }
      elements.forEach((element) => {
        var filtered = this.computedMapData.filter(x => x.geO_ID === element.attribute('GEO_ID'))
        this.metricOptions.forEach((option) => {
          // if (element.attribute('GEO_ID') === '0500000US04013') {
          //   console.log(`${option.value}: ${this.getAggregateMetricValue(option, filtered)}`)
          // }
          element.attribute(option.value, this.getAggregateMetricValue(option, filtered))
        })
      })
      this.colorGroupAssigned = this.computedColorGroup
      this.colorGroupAssigned.push({})
      this.colorGroupAssigned.pop()
    },
    getAggregateMetricValue (metricOption, mapData) {
      if (metricOption.numerator && metricOption.denominator && metricOption.factor) {
        return parseFloat(mapData.reduce((a, b) => +a + +b[metricOption.numerator], 0) / mapData.reduce((a, b) => +a + +b[metricOption.denominator], 0) * metricOption.factor || 0).toFixed(2)
      } else {
        return mapData.reduce((a, b) => +a + +b[metricOption.value], 0) || 0
      }
    },
    getColorGroupBucket () {

    },
    getLegendTitle () {
      var txt = 'Received:<br>'
      var _this = this
      this.mailingsSelected.forEach((item, index) => {
        txt += `${_this.mailingsList.find(x => x.acousticMailingId === item).text2}<br>`
      })
      // txt += `<br style='margin: 0px, 10px;'>${this.selectedMetric.text}:`
      return txt
    },
    customizeLegendItems (items) {
      if (this.selectedMetric.append === '%') {
        items.splice(-1)
        return items
      }
      return items
    },
    customizeLegendText (itemInfo) {
      // if (itemInfo.index === 0) {
      //   return `${format(this.computedColorGroup[itemInfo.index])}`
      // }
      if (itemInfo.index === this.computedColorGroup.length - 2) {
        if (this.selectedMetric.append === '%') return
        return `> ${format(this.computedColorGroup[itemInfo.index])}${this.selectedMetric.append}`
      }
      return `${format(itemInfo.start)}${this.selectedMetric.append} - ${format(itemInfo.end)}${this.selectedMetric.append}`
    },
    getGeoDataDownloadFormat () {
      var list = []
      this.usCounties.features.map(x => x.properties).forEach((county) => {
        var listItem = {}
        var filtered = this.computedMapData.filter(x => x.geO_ID === county.GEO_ID)
        this.metricOptions.forEach((option) => {
          listItem[option.value] = this.getAggregateMetricValue(option, filtered)
        })
        if (listItem.received <= 0) {
          return
        }
        listItem.geoId = county.GEO_ID
        listItem.countyName = `${county.NAME} ${county.LSAD}`
        listItem.county = county.COUNTY
        listItem.countyCensusArea = county.CENSUSAREA
        var state = this.usStates.features.map(x => x.properties).find(x => x.STATE === county.STATE)
        listItem.stateName = state.NAME
        listItem.state = state.STATE
        listItem.stateCensusArea = state.CENSUSAREA
        list.push(listItem)
      })
      return list.sort(
        (a, b) =>
          a.state.localeCompare(b.state) ||
        a.county.localeCompare(b.county)
      )
    },
    changeCampaign () {
      if (this.campaignIdData) {
        this.$router.push({ name: 'CampaignEventsHeatmap', params: { campaignId: this.campaignIdData } }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err
          }
        })
      }
      this.campaignSearch = null
    },
    clearCampaign () {
      this.mailingsSelected = []
      this.mailingsList = []
      this.stateSummaries = []
      this.mapData = null
    },
    getChartOptionsBar (title, metric) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: title
        },
        legend: {
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
            fontSize: 14
          }
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              callback: function (value, index, values) {
                return `${value}${metric.append}`
              }
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: 'State'
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }]
        }
        // plugins: {
        //   datalabels: {
        //     anchor: 'end',
        //     align: 'top',
        //     rotation: -30,
        //     formatter: function (value, context) {
        //       if (!value || value <= 0) return ''
        //       return `${parseFloat(value).toFixed(metric.precision)}${metric.append}`
        //     },
        //     font: {
        //       family: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
        //       weight: 'bold'
        //     }
        //   }
        // }
      }
    }
  }
}
</script>
<style>
/* #vector-map {
  height: 500px;
} */
</style>
